<script>
import sha256 from "crypto-js/sha256";
import { usParse, caParse } from "@/utils/decode-id";
import VxDialogView from "@/components/vx/VxDialogView";

import { injectActiveEmployee } from "@/mixins/employee";

import CheckinButton from "../components/BuyDialogActionButtonCheckin";
import BuyDetailToolbarTitle from "../components/BuyDetailToolbarTitle";
import BuyDialogCustomerForm from "../components/BuyDialogCustomerForm";
import BuyDialogMetaForm from "../components/BuyDialogMetaForm";
import BuyDetailToolbarExtension from "../components/BuyDetailToolbarExtension";
import BuyDialogMessaging from "../components/BuyDialogMessaging";

import get from "lodash/get";
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";

// TODO: remove
import { hasVModel } from "@/mixins/vmodel";

import { UPDATE_CHECKIN_REQUEST, CHECKIN_REQUEST_QUERY } from "../graphql";
import { UPDATE_CUSTOMER } from "@/graphql/customer/mutations.gql";

import gql from "graphql-tag";
const updateIdInfoMutation = gql`
  mutation CUSTOMER_FORM_ADD_ID_INFO(
    $input: AddIdInfoCheckinRequestInputObject!
  ) {
    checkinRequestAddCustomerIdInfo(input: $input) {
      customer {
        id
        driverLicense {
          id
          number
          state
        }
      }
      errors
    }
  }
`;

const verifyLicenseMutation = gql`
  mutation VERIFY_LICENSE($input: VerifyLicenseCheckinRequestInput!) {
    checkinRequestVerifyLicense(input: $input) {
      checkinRequest {
        id
        licenseVerifiedAt
      }
      errors
    }
  }
`;

const storeQuery = gql`
  query STORE_BUY_CONFIG($id: ID!) {
    store(id: $id) {
      id
      state
      buyConfig {
        id
        buysRequireEmail
        buysRequireLicense
      }
    }
  }
`;

export default {
  name: "CheckinRequestDetailViewV1",
  components: {
    VxDialogView,
    CheckinButton,

    BuyDetailToolbarTitle,
    BuyDialogCustomerForm,
    BuyDialogMetaForm,
    BuyDetailToolbarExtension,
    BuyDialogMessaging,
  },
  mixins: [hasVModel, injectActiveEmployee],
  props: {
    checkinRequestId: {
      type: String,
      default: "",
    },
    customerRouteName: {
      type: String,
      required: true,
    },
    storeId: {
      type: [String, Number],
      default: undefined,
    },
    buyDetailsTab: {
      type: String,
      default: "buy",
    },
  },

  // TODO: code smell, fix and remove
  provide() {
    return {
      // Allow forms to register themselves,
      // so we have easy access to their validation
      registerBuyDialogForm: this.registerForm,
      // Allow hiding the dialog from descendants
      hideDialog: () => {
        this.$router.go(-1);
      },
      // Computed validity state for descendants
      formsValidState: () => this.formsValidState,
      // Grants descendants access to dialog v-model
      // so they can handle reset when value changes
      dialogModel: () => this.localValue,
    };
  },

  data: (vm) => ({
    // These 3 forms are acutally registered in the meta, customer and offer form components themselves.
    // Otherwise we can't be reactive to changes in them.
    customerForm: undefined,
    metaForm: undefined,

    customerLoading: false,
    metaLoading: false,

    tab: vm.buyDetailsTab,
    showEdit: false,

    checkinRequest: undefined,

    idVerifyErrors: undefined,
    idVerifyNumber: undefined,
    idVerifyLoading: undefined,

    idNumber: undefined,
    idState: undefined,
    idDataUpdateLoading: undefined,

    store: undefined,
  }),
  computed: {
    ...mapGetters("sockets", ["storeChannelName"]),

    checkinStatus() {
      if (!this.checkinRequest) {
        return undefined;
      }

      return this.checkinRequest.status;
    },

    checkInDisabled() {
      if (this.anyLoading) {
        return true;
      }

      if (!this.checkinRequest) {
        return true;
      }

      // Can't checkin a buy that is not submitted
      if (
        this.checkinRequest.status !== "submitted" &&
        this.checkinRequest.status !== "pending" &&
        this.checkinRequest.status !== "buy_created" // allow retry
      ) {
        return true;
      }

      if (!this.customerForm || !this.metaForm) {
        return true;
      }

      return !this.customerForm.isValid || !this.metaForm.isValid;
    },

    // anyLoading - returns true if any form have requests in flight
    anyLoading() {
      return [this.customerLoading, this.metaLoading].some((e) => e);
    },
    formsValidState() {
      const customerForm = this.customerForm;
      const metaForm = this.metaForm;

      return {
        customerForm: customerForm && customerForm.isValid,
        metaForm: metaForm && metaForm.isValid,
      };
    },
    shouldShowDrsInitialsWarning() {
      return (
        this.checkinRequest &&
        (this.checkinRequest.status === "pending" ||
          this.checkinRequest.status === "submitted") &&
        this.employeeIsMissingDrsInitials
      );
    },
    employeeIsMissingDrsInitials() {
      return this.activeEmployee && !this.activeEmployee.drsEmployeeCode;
    },
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  apollo: {
    store: {
      query: storeQuery,
      variables() {
        return { id: this.storeId };
      },
      skip() {
        return !this.storeId;
      },
    },
    checkinRequest: {
      query: CHECKIN_REQUEST_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          id: this.checkinRequestId,
          storeId: this.storeId,
        };
      },
      skip() {
        return !this.checkinRequestId;
      },
    },
  },
  watch: {
    buyDetailsTab(newTab) {
      this.tab = newTab;
    },
    store(newStore) {
      // set the ID state to the store's state only if the license is required
      if (
        newStore &&
        !this.idState &&
        get(this, "stores.buyConfig.buysRequireLicense", false)
      ) {
        // Set default state for the store, if not set already
        this.idState = this.store.state;
      }
    },
  },
  created() {
    this.mountScanListener();
  },
  destroyed() {
    this.dismountScanListener();
  },
  methods: {
    ...mapMutations("snackbar", ["showSnackbar"]),

    mountScanListener() {
      window.addEventListener("onlicenseinput", this.handleScan);
      window.addEventListener("onbarcode", this.handleBarcode);
    },

    dismountScanListener() {
      window.removeEventListener("onlicenseinput", this.handleScan);
      window.removeEventListener("onbarcode", this.handleBarcode);
    },

    // TODO: fix
    // `refs` are not reactive. Since the `checkin` button
    // relies on a descendant's computed property, we "register"
    // that descendant once it's mounted. That way, we have
    // direct access to its computed `isValid` property.
    registerForm(form, vm) {
      this[form] = vm;
    },

    // TODO: fix and remove?
    handleEdit() {
      [this.$refs.customer, this.$refs.meta, this.$refs.offer].map(
        (vm) => vm && vm.showEdit && vm.showEdit()
      );

      this.showEdit = true;
      this.changeTab("buy");
    },

    handleViewCustomer() {
      const customerRoute = {
        name: this.customerRouteName,
        params: {
          customerId: this.checkinRequest.customerId,
        },
        preserveQuery: true,
      };

      this.$router.replace(customerRoute);
    },

    changeTab(tabName) {
      this.$router.replace({
        name: this.$route.name,
        query: {
          buyDetailsTab: tabName,
        },
        preserveQuery: true,
      });
    },

    async updateCustomerField(field, value, finishedCallback) {
      try {
        const {
          data: {
            checkinRequestUpdate: { errors },
          },
        } = await this.$apollo.mutate({
          mutation: UPDATE_CHECKIN_REQUEST,
          variables: {
            input: {
              id: this.checkinRequestId,
              customerData: {
                [field]: value,
              },
            },
          },
        });
        // Handle any backend errors
        if (errors && errors.length) {
          this.showSnackbar({
            text: `Error occurred while updating checkin request. ${errors.join(
              "; "
            )}`,
          });
          return;
        }
      } catch (error) {
        this.showSnackbar({
          text: `Error occurred while updating checkin request. ${error}`,
        });
      } finally {
        finishedCallback();
      }
    },

    async updateBuyField(field, value, finishedCallback) {
      try {
        const {
          data: {
            checkinRequestUpdate: { errors },
          },
        } = await this.$apollo.mutate({
          mutation: UPDATE_CHECKIN_REQUEST,
          variables: {
            input: {
              id: this.checkinRequestId,
              buyData: {
                [field]: value,
              },
            },
          },
        });

        // Handle any backend errors
        if (errors && errors.length) {
          this.showSnackbar({
            text: `Error occurred while updating checkin request. ${errors.join(
              "; "
            )}`,
          });
          return;
        }
      } catch (error) {
        this.showSnackbar({
          text: `Error occurred while updating checkin request. ${error}`,
        });
      } finally {
        finishedCallback();
      }
    },

    async updateIdInfo(idInfo, finishedCallback) {
      try {
        const {
          data: {
            checkinRequestAddCustomerIdInfo: { errors },
          },
        } = await this.$apollo.mutate({
          mutation: updateIdInfoMutation,
          variables: {
            input: {
              customerId: this.checkinRequest.customerId,
              checkinRequestId: this.checkinRequestId,
              ...idInfo,
            },
          },
        });
        // Handle any backend errors
        if (errors && errors.length) {
          this.showSnackbar({
            text: `Error occurred while updating customer. ${errors.join(
              "; "
            )}`,
          });
          return;
        }
        await this.verifyLicense(idInfo.number.slice(-4));
      } catch (error) {
        this.showSnackbar({
          text: `Error occurred while updating customer. ${error}`,
        });
      } finally {
        finishedCallback();
      }
    },

    async verifyId(idVerifyNumber, finishedCallback) {
      try {
        const {
          data: {
            checkinRequestVerifyLicense: { errors },
          },
        } = await this.verifyLicense(idVerifyNumber);

        // If we've made it this far, no network error occurred. Check for GraphQL errors.
        if (errors && errors.length) {
          this.idVerifyErrors = errors.map(
            (e) => e.charAt(0).toUpperCase() + e.slice(1)
          );
        }
      } catch (error) {
        this.showSnackbar({
          text: `Error occurred while verifying id. ${error}`,
        });
      } finally {
        finishedCallback();
      }
    },

    async handleScan({ detail: { parsed: v, raw } }) {
      const licenseNumber =
        v["documentNumber"] || v["IdNumber"] || v["documentDiscriminator"];
      const licenseState =
        v["addressState"] || get(v, "Address", "JurisdictionCode");

      if (get(this, "checkinRequest.driverLicense.id")) {
        // This means we are dealing with a case to verify last 4 on license, as we already have the license id
        if (licenseNumber) {
          try {
            this.idVerifyNumber = licenseNumber.slice(-4);
            this.idVerifyLoading = true;
            const {
              data: {
                verifyLicense: { errors },
              },
            } = await this.verifyLicense(licenseNumber.slice(-4));

            // If we've made it this far, no network error occurred. Check for GraphQL errors.
            if (errors && errors.length) {
              this.idVerifyErrors = errors.map(
                (e) => e.charAt(0).toUpperCase() + e.slice(1)
              );
            }
          } finally {
            this.idVerifyLoading = false;
          }
        }
      } else {
        this.idNumber = licenseNumber;
        this.idState = licenseState;
        this.idDataUpdateLoading = true;

        const licenseData = v;
        const licenseHash = sha256(JSON.stringify(v)).toString();

        const {
          data: {
            updateCustomer: { errors },
          },
        } = await this.updateCustomerLicenseData(
          this.checkinRequest.customerId,
          licenseData,
          licenseHash
        );

        // If we've made it this far, no network error occurred. Check for GraphQL errors.
        if (errors && errors.length) {
          // TODo - handle errors when updating license
        } else {
          await this.$apollo.mutate({
            mutation: UPDATE_CHECKIN_REQUEST,
            variables: {
              input: {
                id: this.checkinRequestId,
                customerData: {
                  licenseVerifiedAt: licenseData ? new Date().toJSON() : null,
                },
              },
            },
          });
          // TODO - handle errors
        }

        this.idDataUpdateLoading = false;
      }
    },

    // Takes a scanned barcode and parses it to see if it is a license
    async handleBarcode({ detail: { data: v } }) {
      const leadingChar = v.charCodeAt(0);
      // Guard. If the barcode doesn't begin with either `@` or `%`, just return
      if ([64, 37].indexOf(leadingChar) < 0) return;

      let parsed;
      // If the first character is `@`, try to decode with usParse
      // If the first character is `%`, try to decode with caParse
      if (leadingChar === 64) {
        parsed = usParse(v);
      }
      if (leadingChar === 37) {
        parsed = caParse(v);
      }
      // If the return object has any errors, log them
      if (parsed.errors && parsed.errors.length) {
        try {
          const checksum = btoa(
            JSON.stringify({
              v,
              errors: parsed.errors,
            })
          );
          if (!!raiPos) {
            raiPos.writeLog(`checksum errors ${checksum}`);
          }
        } catch (error) {
          console.log("Error generating checksum", error);
        }
      }
      const compat = { detail: { parsed, raw: v } };
      // Pass the decoded result on to `handleScan`
      return this.handleScan(compat);
    },

    // Duplicate of VerifyLicense#handleInput
    async verifyLicense(last4) {
      // Guard
      if (!last4 || last4.length < 4) return;
      return this.$apollo.mutate({
        mutation: verifyLicenseMutation,
        variables: {
          input: {
            id: this.checkinRequestId,
            last4: last4,
          },
        },
      });
    },

    async updateCustomerLicenseData(customerId, licenseData, licenseHash) {
      return this.$apollo.mutate({
        mutation: UPDATE_CUSTOMER,
        variables: {
          input: {
            id: customerId,
            licenseHash: licenseHash,
            licenseData:
              licenseData === "string"
                ? licenseData
                : JSON.stringify(licenseData),
          },
        },
      });
    },
  },
};
</script>

<template>
  <VxDialogView
    :retain-focus="false"
    :v-size="'large'"
    :h-size="'medium'"
    :error-toolbar="
      checkinRequest &&
      checkinRequest.customer &&
      checkinRequest.customer.flagged
    "
  >
    <template v-if="!isSmallScreen && checkinRequest" #actions>
      <CheckinButton
        color="primary"
        :checkin-request="checkinRequest"
        :disabled="checkInDisabled"
      />
    </template>

    <template v-if="isSmallScreen && checkinRequest" #large-actions>
      <CheckinButton
        color="primary"
        :checkin-request="checkinRequest"
        :disabled="checkInDisabled"
      />
    </template>

    <template #toolbar-title>
      <!-- // TODO - see if to send checkinrequest as object, or desconstructed -->
      <BuyDetailToolbarTitle
        v-bind="{ ...checkinRequest, reprintDisabled: true }"
        @viewCustomer="handleViewCustomer"
        @reprintSlips="() => {}"
        @edit="handleEdit"
      />
    </template>

    <template #toolbar-extension>
      <BuyDetailToolbarExtension
        :status="checkinStatus"
        :tab="tab"
        @changeTab="changeTab"
      />
    </template>

    <template>
      <v-banner v-if="shouldShowDrsInitialsWarning" sticky>
        <v-avatar slot="icon" size="24px">
          <v-icon color="error" v-text="`$alert`" />
        </v-avatar>
        <template>
          This employee cannot check-in buys because their DRS initials are
          missing. Please add DRS initials, or have another employee log in to
          finish checking in
        </template>
      </v-banner>
      <v-tabs-items
        v-if="checkinRequest"
        v-model="tab"
        touchless
        class="fill-height"
      >
        <!-- BuyMeta+BuyOffer Tab -->
        <v-tab-item value="buy" class="fill-height">
          <BuyDialogCustomerForm
            v-if="checkinRequest && store"
            v-bind="{
              ...checkinRequest,
              buyId: checkinRequest.id,
              licenseVerified: !!checkinRequest.licenseVerifiedAt,

              idVerifyErrors: idVerifyErrors,
              idVerifyNumber: idVerifyNumber,
              idVerifyLoading: idVerifyLoading,
              idNumber: idNumber,
              idState: idState,
              idDataUpdateLoading: idDataUpdateLoading,
              store: store,
            }"
            @loading="(v) => $emit('loading', v)"
            @update:field="updateCustomerField"
            @update:idField="updateIdInfo"
            @update:idVerifyField="verifyId"
          />
          <BuyDialogMetaForm
            v-if="checkinRequest"
            class="mt-sm-6 mt-3"
            v-bind="{
              ...checkinRequest,
            }"
            @loading="(v) => $emit('loading', v)"
            @update:field="updateBuyField"
          />
        </v-tab-item>
        <!-- Messaging Tab -->
        <v-tab-item value="message" class="fill-height">
          <BuyDialogMessaging
            v-if="checkinRequest"
            :customer-id="checkinRequest.customerId || undefined"
          />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </VxDialogView>
</template>

<style scoped lang="scss">
::v-deep .number-name-wrapper {
  overflow: hidden;

  .name-wrapper {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.v-tabs-items {
  overflow: visible;
}
</style>
